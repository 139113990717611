<script setup lang="ts">
    import { customerExistsApi } from '@containex/portal-backend-api-client';
    import FormField from '@/components/form-components/FormField.vue';
    import { SalutationType } from '@/types/Salutation';
    import { hasValidationError } from '@/util/hasValidationError';
    import { type CustomerDto, CustomerRole } from '@containex/portal-backend-dto';
    import { toTypedSchema } from '@vee-validate/zod';
    import { useMemoize } from '@vueuse/core';
    import Button from 'primevue/button';
    import InputText from 'primevue/inputtext';
    import MultiSelect from 'primevue/multiselect';
    import { useForm } from 'vee-validate';
    import { useI18n } from 'vue-i18n';
    import { array, nativeEnum, object, string } from 'zod';
    import type { CustomerUpdateData } from '../model/customer-update';
    import RadioButtonField from '@/components/form-components/RadioButtonField.vue';
    import { httpClient } from '@/common/api/http-client';

    const props = defineProps<{
        customer?: CustomerDto;
        showCancelButton?: boolean;
        restrictedMode?: boolean;
    }>();

    const emits = defineEmits<{
        cancelForm: [];
        update: [CustomerUpdateData];
    }>();

    const { t } = useI18n();

    const roleOptions = [
        {
            label: t('ACCOUNT.ROLE.ADMIN'),
            role: CustomerRole.ADMIN,
        },
        {
            label: t('ACCOUNT.ROLE.CUSTOMER'),
            role: CustomerRole.CUSTOMER,
        },
        {
            label: t('ACCOUNT.ROLE.CONSIGNEE'),
            role: CustomerRole.CONSIGNEE,
        },
        {
            label: t('ACCOUNT.ROLE.INVOICE_RECIPIENT'),
            role: CustomerRole.INVOICE_RECIPIENT,
        },
    ];

    const customerExistsCheck = useMemoize(async (value: string) => {
        return await customerExistsApi.customerExists(httpClient, { email: value });
    });

    const schema = object({
        email: string()
            .email(t('ERROR.INVALID_EMAIL'))
            .refine(
                async (value) => {
                    if (value === '') {
                        return true;
                    }

                    if (value === props.customer?.email) {
                        return true;
                    }

                    const response = await customerExistsCheck(value);
                    const state = response.data.state;

                    return state === 'still-free';
                },
                {
                    message: t('ERROR.CUSTOMER_ALREADY_EXISTS.ALREADY_EXISTS'),
                }
            ),
        jobTitle: string().trim().optional(),
        firstName: string().trim().min(1),
        lastName: string().trim().min(1),
        phoneBusiness: string().trim().min(1),
        phoneMobile: string().trim().min(1),
        salutation: string().trim().min(1).default(SalutationType.MALE),
        roles: array(nativeEnum(CustomerRole)),
    });

    const { handleSubmit, errors, defineField } = useForm({
        validationSchema: toTypedSchema(schema),
    });

    const [jobTitle] = defineField('jobTitle');
    const [firstName] = defineField('firstName');
    const [lastName] = defineField('lastName');
    const [phoneBusiness] = defineField('phoneBusiness');
    const [phoneMobile] = defineField('phoneMobile');
    const [salutation] = defineField('salutation');
    const [email] = defineField('email');
    const [roles] = defineField('roles');

    if (props.customer != null) {
        jobTitle.value = props.customer.job_title ?? '';
        firstName.value = props.customer.first_name ?? '';
        lastName.value = props.customer.last_name ?? '';
        phoneBusiness.value = props.customer.phone_business ?? '';
        phoneMobile.value = props.customer.phone_mobile ?? '';
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        salutation.value = (props.customer.salutation as SalutationType) ?? SalutationType.MALE;
        email.value = props.customer.email ?? '';
        roles.value = props.customer.roles ?? [];
    }

    const onSubmit = handleSubmit((values) => {
        emits('update', {
            email: values.email.toLowerCase(),
            last_name: values.lastName,
            first_name: values.firstName,
            salutation: values.salutation,
            phone_business: values.phoneBusiness,
            phone_mobile: values.phoneMobile,
            roles: values.roles,
            job_title: values.jobTitle,
        });
    });
</script>

<template>
    <form class="add-user-container">
        <div class="radio-fields">
            <RadioButtonField
                v-model="salutation"
                :value="SalutationType.MALE"
                :label="t('FORM.SALUTATION_MALE')"
                :error-message="errors.salutation"
            />
            <RadioButtonField
                v-model="salutation"
                :value="SalutationType.FEMALE"
                :label="t('FORM.SALUTATION_FEMALE')"
                :error-message="errors.salutation"
            />
        </div>
        <FormField :label="t('FORM.POSITION')" :error-message="errors.jobTitle">
            <InputText v-model="jobTitle" class="full-width" :invalid="hasValidationError(errors.jobTitle)" />
        </FormField>
        <FormField :label="t('FORM.FIRST_NAME')" :error-message="errors.firstName">
            <InputText v-model="firstName" class="full-width" :invalid="hasValidationError(errors.firstName)" />
        </FormField>
        <FormField :label="t('FORM.LAST_NAME')" :error-message="errors.lastName">
            <InputText v-model="lastName" class="full-width" :invalid="hasValidationError(errors.lastName)" />
        </FormField>
        <FormField :label="t('FORM.EMAIL')" :error-message="errors.email">
            <InputText
                v-model="email"
                class="full-width"
                :invalid="hasValidationError(errors.email)"
                :disabled="restrictedMode === true"
            />
        </FormField>
        <FormField :label="t('FORM.PHONE_BUSINESS')" :error-message="errors.phoneBusiness">
            <InputText v-model="phoneBusiness" class="full-width" :invalid="hasValidationError(errors.phoneBusiness)" />
        </FormField>
        <p class="small-secondary-text">{{ t('FORM.INCLUSIVE_COUNTRY_CODE') }}</p>
        <FormField :label="t('FORM.PHONE_MOBILE')" :error-message="errors.phoneMobile">
            <InputText v-model="phoneMobile" class="full-width" :invalid="hasValidationError(errors.phoneMobile)" />
        </FormField>
        <FormField :label="t('FORM.ROLES')" :error-message="errors.roles">
            <MultiSelect
                v-model="roles"
                display="chip"
                :options="roleOptions"
                option-label="label"
                option-value="role"
                class="full-width"
                :show-toggle-all="false"
                :invalid="hasValidationError(errors.roles)"
                :disabled="restrictedMode === true"
            />
        </FormField>

        <br />
        <div class="actions">
            <Button
                v-if="showCancelButton !== true"
                :label="t('ACCOUNT.INVITE_DIALOG.CANCEL')"
                outlined
                type="button"
                autofocus
                @click="emits('cancelForm')"
            />
            <Button
                :label="customer == null ? t('ACCOUNT.INVITE_DIALOG.SUBMIT') : t('ACCOUNT.INVITE_DIALOG.EDIT')"
                type="button"
                @click="onSubmit"
            />
        </div>
    </form>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .add-user-container {
        background: main.$vt-c-white;
        display: flex;
        flex-direction: column;
    }

    .actions {
        display: flex;
        justify-content: end;
        gap: 1ch;
    }

    .radio-fields {
        display: flex;
        gap: 2ch;
    }

    .button {
        justify-content: center;
    }

    .small-secondary-text {
        font-size: main.$font-size-sm;
        color: main.$color-secondary;
        margin: 0;
    }
</style>
