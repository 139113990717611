<script setup lang="ts">
    import EditUserForm from '@/account/components/EditUserForm.vue';
    import { companyCustomersApi } from '@containex/portal-backend-api-client';
    import { useCompanyAction } from '@/composables/company';
    import useGlobalToast from '@/composables/useGlobalToast';
    import { type CustomerDto } from '@containex/portal-backend-dto';
    import { HttpStatusCode } from 'axios';
    import Dialog from 'primevue/dialog';
    import { useI18n } from 'vue-i18n';
    import type { CustomerUpdateData } from '@/account/model/customer-update';
    import { getLogger } from '@/logger/logger';
    import { httpClient } from '@/common/api/http-client';

    const props = defineProps<{
        isVisible: boolean;
        customer?: CustomerDto;
    }>();

    const emits = defineEmits<{
        close: [];
        update: [];
    }>();

    const { t } = useI18n();
    const companyAction = useCompanyAction();
    const { errorToastOptions, defaultToastOptions, addToast } = useGlobalToast();
    const logger = getLogger('AddCustomerDialog');

    async function submit(data: CustomerUpdateData): Promise<void> {
        const company = await companyAction.findCustomerCompany();

        if (company == null) {
            addToast(errorToastOptions);
            return;
        }

        const customerData = {
            email: data.email,
            lastName: data.last_name,
            firstName: data.first_name,
            salutation: data.salutation,
            language: company.language,
            phoneBusiness: data.phone_business,
            phoneMobile: data.phone_mobile,
            roles: data.roles,
            jobTitle: data.job_title,
        };

        const response =
            props.customer == null
                ? await companyCustomersApi.addCustomer(httpClient, customerData)
                : await companyCustomersApi.updateCustomer(httpClient, { customerId: props.customer.id }, customerData);

        if (response.status === HttpStatusCode.Ok) {
            addToast({
                ...defaultToastOptions,
                severity: 'success',
                summary: 'ACCOUNT.INVITE_DIALOG.INVITE_SUCCESS_SUMMARY',
                detail:
                    props.customer == null
                        ? 'ACCOUNT.INVITE_DIALOG.INVITE_DETAIL'
                        : 'ACCOUNT.INVITE_DIALOG.UPDATE_DETAIL',
                detailValues: { email: data.email },
            });
            emits('update');
        } else {
            logger.error('Error happened while updating customer', response);
            addToast(errorToastOptions);
        }
    }
</script>

<template>
    <Dialog
        :visible="isVisible"
        modal
        :header="customer == null ? t('ACCOUNT.INVITE_DIALOG.HEADER') : t('ACCOUNT.INVITE_DIALOG.UPDATE_HEADER')"
        :style="{ width: '40rem', 'max-width': '100%' }"
        :pt="{ closeButton: { onClick: () => emits('close') } }"
        @hide="emits('close')"
    >
        <EditUserForm
            :customer="customer"
            class="content"
            @update="submit"
            @cancel-form="emits('close')"
        ></EditUserForm>
    </Dialog>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .content {
        padding-bottom: main.$spacing-6;
    }
</style>
