<script setup lang="ts">
    import { companyCustomersApi } from '@containex/portal-backend-api-client';
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import AddCustomerDialog from '@/account/dialogs/AddCustomerDialog.vue';
    import { useAuthenticationAction, useAuthenticationQuery } from '@/composables/authentication';
    import { useCustomerAction, useCustomerQuery } from '@/composables/customer';
    import { type CustomerDto, type FetchPaginatedDataDto, PaginationStep } from '@containex/portal-backend-dto';
    import Button from 'primevue/button';
    import InputSwitch from 'primevue/inputswitch';
    import Column from 'primevue/column';
    import DataTable, { type DataTablePageEvent } from 'primevue/datatable';
    import BlockUI from 'primevue/blockui';
    import Tag from 'primevue/tag';
    import { ref } from 'vue';
    import { useAsyncTask } from 'vue-concurrency';
    import { useI18n } from 'vue-i18n';
    import { DEFAULT_ROW_AMOUNT_OPTIONS_TABLE, DEFAULT_ROWS_PER_TABLE } from '@/constants';
    import { getLogger } from '@containex/logger';
    import { httpClient } from '@/common/api/http-client';
    import { isStringEmpty } from '@containex/common-utils';
    import InviteCustomerDialog from '@/account/dialogs/InviteCustomerDialog.vue';
    import { useCompanyAction } from '@/composables/company';

    const { currentCustomer } = useAuthenticationQuery();
    const { customers, totalCustomerAmount } = useCustomerQuery();
    const customerAction = useCustomerAction();
    const { isAdmin } = useAuthenticationQuery();
    const authenticationAction = useAuthenticationAction();
    const companyAction = useCompanyAction();
    const { t } = useI18n();
    const logger = getLogger('UserList');

    const selectedCustomer = ref<CustomerDto | undefined>();
    const addCustomerDialogIsVisible = ref(false);
    const inviteCustomerDialogVisible = ref(false);

    const isLoading = ref(false);
    const paginatorCurrentRows = ref(DEFAULT_ROWS_PER_TABLE);
    const paginatorCurrentPage = ref(0);
    const paginatorPageLinkSize = ref(2);

    const task = useAsyncTask(async () => {
        await authenticationAction.fetchCurrentCustomerIfMissing.perform();
        await customerAction.fetchCustomers(paginatorCurrentRows.value, PaginationStep.New);
    }).perform();

    const company = useAsyncTask(async () => {
        return await companyAction.findCustomerCompany();
    }).perform();

    const fetchPaginatedCustomers = useAsyncTask(async (signal, data: FetchPaginatedDataDto) => {
        logger.debug('UserList', 'fetching users for table');
        isLoading.value = true;
        await customerAction.fetchCustomers(data.dataAmount, data.step);
        isLoading.value = false;
    }).drop();

    async function refetchCustomers(): Promise<void> {
        addCustomerDialogIsVisible.value = false;

        await fetchPaginatedCustomers.perform({
            dataAmount: paginatorCurrentRows.value,
            step: PaginationStep.Same,
        });
    }

    async function changeUserStatus(user: CustomerDto, activeUser: boolean): Promise<void> {
        if (activeUser) {
            await companyCustomersApi.activateCustomer(httpClient, user.id);
        } else {
            await companyCustomersApi.deactivateCustomer(httpClient, user.id);
        }

        await refetchCustomers();
    }

    async function handlePaginatorChange(event: DataTablePageEvent): Promise<void> {
        if (paginatorCurrentPage.value === event.page && paginatorCurrentRows.value === event.rows) {
            return;
        }

        if (paginatorCurrentRows.value !== event.rows) {
            paginatorCurrentRows.value = event.rows;
            await fetchPaginatedCustomers.perform({
                dataAmount: event.rows,
                step: PaginationStep.New,
            });
            event.page = 0;
        } else {
            await fetchPaginatedCustomers.perform({
                dataAmount: event.rows,
                step: paginatorCurrentPage.value < event.page ? PaginationStep.Next : PaginationStep.Previous,
            });
        }

        paginatorCurrentPage.value = event.page;
        getPageLinkSize();
    }

    function getPageLinkSize(): void {
        const lastPage = Math.ceil(totalCustomerAmount.value / paginatorCurrentRows.value) - 1;
        if (paginatorCurrentPage.value === 0 || paginatorCurrentPage.value === lastPage) {
            paginatorPageLinkSize.value = 2;
        } else {
            paginatorPageLinkSize.value = 3;
        }
    }
</script>
<template>
    <div v-if="task.isSuccessful && company.isSuccessful">
        <BlockUI :blocked="fetchPaginatedCustomers.isRunning">
            <DataTable
                v-if="(customers != null && customers.length > 0) || fetchPaginatedCustomers.isRunning"
                lazy
                :value="customers"
                :row-class="
                    ({ deletedAt }) => {
                        if (deletedAt != null) {
                            return [{ deactivated: true }];
                        }

                        return [];
                    }
                "
                paginator
                :total-records="totalCustomerAmount"
                :rows="paginatorCurrentRows"
                :rows-per-page-options="DEFAULT_ROW_AMOUNT_OPTIONS_TABLE"
                :loading="isLoading"
                :page-link-size="paginatorPageLinkSize"
                :first="paginatorCurrentPage * paginatorCurrentRows"
                :current-page-report-template="
                    t('ACCOUNT.PAGINATOR', {
                        first: '{first}',
                        last: '{last}',
                        totalRecords: '{totalRecords}',
                    })
                "
                paginator-template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                @page="handlePaginatorChange"
            >
                <Column field="deletedAt" :header="t('ACCOUNT.ACTIVE')">
                    <template #body="slotProps: { data: CustomerDto }">
                        <InputSwitch
                            v-if="currentCustomer?.id != slotProps.data.id"
                            :model-value="slotProps.data.deletedAt == null"
                            @update:model-value="changeUserStatus(slotProps.data, $event)"
                        />
                    </template>
                </Column>
                <Column field="salutation" :header="t('ACCOUNT.COMPANY.SALUTATION')">
                    <template #body="slotProps: { data: CustomerDto }">
                        {{ t('ENUMS.SALUTATION.' + slotProps.data.salutation) }}
                    </template>
                </Column>
                <Column field="name" :header="t('ACCOUNT.COMPANY.NAME')">
                    <template #body="slotProps: { data: CustomerDto }">
                        {{ slotProps.data.first_name }} {{ slotProps.data.last_name }}
                    </template>
                </Column>
                <Column field="name" :header="t('ACCOUNT.COMPANY.POSITION')">
                    <template #body="slotProps: { data: CustomerDto }"> {{ slotProps.data.job_title }}</template>
                </Column>
                <Column field="email" :header="t('ACCOUNT.COMPANY.EMAIL')">
                    <template #body="slotProps: { data: CustomerDto }">
                        <a
                            v-if="!isStringEmpty(slotProps.data.email)"
                            :href="'mailto: ' + slotProps.data.email"
                            class="mail-link"
                        >
                            {{ slotProps.data.email }}
                        </a>
                    </template>
                </Column>
                <Column field="phone_business" :header="t('ACCOUNT.COMPANY.PHONE')" />
                <Column field="phone_mobile" :header="t('ACCOUNT.COMPANY.MOBILE')" />
                <Column field="roles" :header="t('ACCOUNT.COMPANY.ROLES')">
                    <template #body="slotProps: { data: CustomerDto }">
                        <Tag
                            v-for="role in slotProps.data.roles"
                            :key="role"
                            :value="t('ACCOUNT.ROLE.' + role)"
                            class="role"
                        />
                    </template>
                </Column>
                <Column class="edit" field="edit">
                    <template #body="slotProps: { data: CustomerDto }">
                        <div v-if="slotProps.data.deletedAt == null" class="edit-button-container">
                            <Button
                                v-if="!slotProps.data.general_terms_accepted && isAdmin"
                                icon="pi pi-envelope"
                                outlined
                                @click="
                                    selectedCustomer = slotProps.data;
                                    inviteCustomerDialogVisible = true;
                                "
                            />
                            <Button
                                icon="pi pi-pencil"
                                :class="slotProps.data.general_terms_accepted || !isAdmin ? 'solo-flex-button' : ''"
                                :disabled="!isAdmin"
                                outlined
                                @click="
                                    selectedCustomer = slotProps.data;
                                    addCustomerDialogIsVisible = true;
                                "
                            />
                        </div>
                    </template>
                </Column>
            </DataTable>
            <p v-else>
                {{ t('ACCOUNT.CTX_ORDERS.NO_ORDERS') }}
            </p>
        </BlockUI>
    </div>
    <LoadingSpinner v-else-if="task.isRunning" />

    <AddCustomerDialog
        v-if="addCustomerDialogIsVisible"
        :is-visible="addCustomerDialogIsVisible"
        :customer="selectedCustomer"
        @close="addCustomerDialogIsVisible = false"
        @update="refetchCustomers"
    />

    <InviteCustomerDialog
        v-if="inviteCustomerDialogVisible && selectedCustomer != null"
        :is-visible="inviteCustomerDialogVisible"
        :customer="selectedCustomer"
        :company="company.value"
        @close="inviteCustomerDialogVisible = false"
        @update="inviteCustomerDialogVisible = false"
    />
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .solo-flex-button {
        margin-left: auto;
    }

    .edit-button-container {
        display: flex;
        gap: main.$spacing-2;
    }

    .role {
        margin-right: main.$spacing-2;
        margin-bottom: main.$spacing-2;
        background: main.$color-background-lightblue;
        color: main.$textColor;
    }

    .mail-link {
        color: main.$ctx-primary-color;
        text-decoration: none;
    }

    .mail-link:hover {
        color: main.$color-primary-darker;
        text-decoration: underline;
    }

    :deep(.deactivated) {
        color: main.$color-secondary;
    }

    :deep(.edit) {
        text-align: center;
    }
</style>
